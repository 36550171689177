import { Colors } from '../../../config/colors';

export const styles = {
  contentBox: {
    marginTop: '88px',
    textAlign: 'left',
    width: '45rem',
  },
  backToAction: {
    display: 'flex',
    color: Colors.primaryText,
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: '23px',
  },
  title: {
    marginBottom: '24px',
  },
  arrowContainer: {
    marginRight: '2px',
  },
  passwordRequirements: {
    margin: '17px 0 32px',
  },
  buttonContainer: {
    width: '21.2rem',
    height: '3.2rem',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '33px',
  },
};
