import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import {
  ContentBox,
  OneLineTextInput,
  LinkComponent,
  Checkbox,
  ThemedButton,
} from '../../../components';
import { cardContainer } from '../styles';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { login } from '../../../clients/loginClient';
import axios from 'axios';
import { useState } from 'react';
import { errorText } from '../../../config/sharedStyles';
import { useAppDispatch } from '../../../app/hooks';
import { loginActionCreator } from '../../login/loginSlice';
import { ROUTES } from '../../../config/constants';

type LoginFormData = {
  email: string;
  password: string;
  remember: boolean;
};

const useLoginPageStyles = createUseStyles({
  cardContainer,
  errorText,
  errorTextLink: {
    ...errorText,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  errorContainer: {
    display: 'flex',
    gap: '5px',
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const LoginForm: React.FC = () => {
  const classes = useLoginPageStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>();

  const onSubmit = data => {
    const config = login(data.email, data.password);
    axios(config as any)
      .then(function (response) {
        const accessToken = response.data.with.access_token;
        const refreshToken = response.data.with.refresh_token;
        const payload = { accessToken, refreshToken };
        dispatch(loginActionCreator(payload));
        history.push('dashboard');
      })
      .catch(function (error) {
        setErrorMessage(error.response.data.because || error.message);
      });
  };

  const onError = (errors, e) => {
    //Do Stuff on Form Error
    console.error(errors);
    console.error(e);
  };

  const { t } = useTranslation();

  return (
    <>
      {errorMessage === 'Account is not fully set up' ? (
        <div className={classes.errorContainer}>
          <div className={classes.errorText}>{errorMessage}.</div>
          <div
            className={classes.errorTextLink}
            onClick={() => history.push(ROUTES.verifyEmail)}
          >
            Click here to resend verification
          </div>
        </div>
      ) : (
        <div className={classes.errorContainer}>
          <div className={classes.errorText}>{errorMessage}</div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit, onError)} className={classes.form}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <OneLineTextInput
            placeholder={t('email')}
            type="text"
            {...register('email', { required: 'Please enter an email' })} //TODO: Resolve type issue and use translations string
            formErrors={errors}
          />
          <OneLineTextInput
            placeholder={t('password')}
            type="password"
            {...register('password', { required: 'Please enter a password' })} //TODO: Resolve type issue and use translations string
            formErrors={errors}
          />
        </div>
        <Checkbox
          label={t('remember_me')}
          onChange={() => console.log('checkbox clicked')}
        />
        <div className={classes.linkContainer}>
          <LinkComponent
            label={t('forgot_password')}
            onClick={() => console.log('link clicked')}
          />
        </div>
        <ThemedButton
          title={t('login_header')}
          onClick={() => {}}
          type="submit"
        />
      </form>
    </>
  );
};

export const LoginPage: React.FC = () => {
  const classes = useLoginPageStyles();
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.cardContainer}>
        <ContentBox title={t('login_header')}>
          <LoginForm />
        </ContentBox>
      </div>
      <div className={classes.cardContainer}>
        <ContentBox title={t('new_to_alive')}>
          <ThemedButton
            onClick={() => history.push('createAccount')}
            title={t('create_an_account')}
          />
        </ContentBox>
      </div>
      <div className={classes.cardContainer}>
        <ContentBox title={t('have_guest_access')}>
          <ThemedButton
            onClick={() => {
              history.push(ROUTES.shareEnterCode);
            }}
            title={t('view_guest_access')}
          />
        </ContentBox>
      </div>
    </>
  );
};
