import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { createAccountClientConfig } from '../../../clients/loginClient';
import { ThemedButton } from '../../../components';
import { ContentBox } from '../../../components/ContentBox/ContentBox';
import { OneLineTextInput } from '../../../components/OneLineTextInput/OneLineTextInput';
import { sharedValidators } from '../../../config/constants';
import { CreateAccountData } from '../../../config/SharedTypes';
import {
  selectOnboarding,
  setOnboarding,
} from '../../dashboard/dashboardSlice';
import { createNewUser } from '../confirm-information/utils';
import { styles } from './styles';
import { useRef } from 'react';

const useCreateLoginStyles = createUseStyles({
  ...styles,
});

export type LoginFormType = CreateAccountData & { confirmPassword: string };

export const CreateLogin = () => {
  const { t } = useTranslation();
  const classes = useCreateLoginStyles();
  const dispatch = useAppDispatch();
  const { confirmInformation } = useAppSelector(selectOnboarding);
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginFormType>();

  const password = useRef({});
  password.current = watch('password', '');

  const onSubmit = data => {
    const newUser = createNewUser(confirmInformation, data);
    const axiosConfig = createAccountClientConfig(data, newUser);
    axios(axiosConfig as any)
      .then(response => {
        const update = {
          confirmInformation,
          patientRecord: createNewUser(
            confirmInformation,
            data,
            response.data.with
          ),
          progressPercent: 95,
        };
        dispatch(setOnboarding(update));
        //ToDo: - Need to persist the data structure somehow
        history.push(`verifyEmail`);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage(error.response.data.because || error.message);
      });
  };

  return (
    <div className={classes.cardContainer}>
      <ContentBox title={t('create_login')} slimTitle>
        <div className={classes.errorText}>{errorMessage}</div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <OneLineTextInput
            placeholder={t('at_username')}
            type="text"
            {...register('username', { required: 'Please enter a username' })}
            formErrors={errors}
          />
          <div className={classes.detailText}>{t('username_explanation')}</div>
          <OneLineTextInput
            placeholder={t('email')}
            type="text"
            {...register('email', { required: 'Please enter an email' })} //TODO: Resolve type issue and use translations string
            formErrors={errors}
          />
          <OneLineTextInput
            placeholder={t('phone_number')}
            type="tel"
            {...register('phone', {
              required: sharedValidators.phoneNumber.pattern.message,
              ...sharedValidators.phoneNumber,
            })}
            formErrors={errors}
          />
          <OneLineTextInput
            placeholder={t('password')}
            type="password"
            {...register('password', {
              required: 'Please enter a password',
              validate: value =>
                (value.length > 8 &&
                  /\d/.test(value) &&
                  /[A-Z]/.test(value) &&
                  /[a-z]/.test(value) &&
                  /\W/.test(value)) ||
                'Please match password with password requirements',
            })}
            formErrors={errors}
          />
          <OneLineTextInput
            placeholder={t('confirm_password_title_case')}
            type="password"
            {...register('confirmPassword', {
              required: 'Please confirm your password',
              validate: value =>
                value === password.current ||
                'Please confirm with matching password',
            })}
            formErrors={errors}
          />
          <div className={classes.detailText}>{t('password_details')}</div>
          <div className={classes.buttonContainer}>
            <ThemedButton
              title={t('continue')}
              onClick={() => {}}
              type="submit"
            />
          </div>
        </form>
      </ContentBox>
    </div>
  );
};
