import { styles } from './styles';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { FormHeader } from '../../../components/TextComponents';
import { AboutMeTitle } from '../../../components/TextComponents/AboutMeTitle';
import { FormPrompt } from '../../../components/TextComponents/FormPrompt';
import { LinkComponent } from '../../../components';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { logout } from '../../login/loginSlice';
import { selectSection } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { flattenUserData } from '../about/utils';

const useAccountSettingStyles = createUseStyles(styles);

export const AccountSettings: React.FC = () => {
  const classes = useAccountSettingStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { data: about } = useAppSelector(
    selectSection(DashboardSections.ABOUT_ME)
  );
  const dispatch = useAppDispatch();
  const flatData = flattenUserData(about);
  const { email, telecom: phone, username } = flatData;
  // TODO: change currentUser value when have real data

  return (
    <div className={classes.contentBox}>
      <FormHeader>{t('account_details')}</FormHeader>
      <div className={classes.title}>
        <AboutMeTitle>{t('email')}</AboutMeTitle>
      </div>
      <div className={classes.info}>
        <FormPrompt>{email}</FormPrompt>
      </div>
      <div className={classes.line} />
      <div className={classes.title}>
        <AboutMeTitle>{t('@username')}</AboutMeTitle>
      </div>
      <div className={classes.info}>
        <FormPrompt>@{username}</FormPrompt>
      </div>
      <div className={classes.line} />
      <div className={classes.title}>
        <AboutMeTitle>{t('phone_number')}</AboutMeTitle>
      </div>
      <div className={classes.info}>
        <FormPrompt>{phone}</FormPrompt>
      </div>
      <div className={classes.line} />
      <div className={classes.linkContainer}>
        <LinkComponent
          label="Change password"
          onClick={() => history.push('/dashboard/changePassword')}
        />
      </div>
      <div className={classes.buttonContainer}>
        <button
          className={classes.button}
          onClick={() => {
            dispatch(logout());
          }}
        >
          <AboutMeTitle>{t('logout')}</AboutMeTitle>
        </button>
      </div>
      <div className={classes.footer}>
        <LinkComponent
          label={t('terms_conditions')}
          onClick={() => console.log('link clicked')}
        />
        <LinkComponent
          label={t('privacy_policy')}
          onClick={() => console.log('link clicked')}
        />
        <LinkComponent
          label={t('support')}
          onClick={() => console.log('link clicked')}
        />
      </div>
    </div>
  );
};
