import { Colors } from '../../config/colors';
import { Classes } from 'jss';
import { createUseStyles } from 'react-jss';

export const useStarProgressStyles: (
  data?: { percent: number; theme: Jss.Theme } & {
    theme?: Jss.Theme | undefined;
  }
) => Classes<
  | 'purpleBox'
  | 'title'
  | 'right'
  | 'left'
  | 'xBox'
  | 'centering'
  | 'barBox'
  | 'starBox'
  | 'backgroundBar'
  | 'filledBar'
> = createUseStyles({
  purpleBox: {
    display: 'flex',
    backgroundColor: Colors.linkPurple,
    color: Colors.white,
    padding: '10px 10px 0px 10px',
    width: '94%',
  },
  left: {
    width: '95%',
  },
  right: {},
  xBox: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    marginRight: '10px',
  },
  centering: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  starBox: ({ percent }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    left: `calc(${percent}% - 16px)`,
    top: '-20px',
    zIndex: 1,
  }),
  barBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '21px',
    marginTop: '10px',
  },
  backgroundBar: {
    width: '100%',
    height: '7px',
    background: Colors.white,
    borderRadius: '45px',
    marginTop: '10px',
  },
  filledBar: ({ percent }) => ({
    backgroundColor: Colors.progressYellow,
    borderRadius: '45px',
    height: '7px',
    width: `${percent}%`,
  }),
});
