import { useEffect } from 'react';
import { OneLineTextInput } from '../../../components';
import { sharedValidators } from '../../../config/constants';
import i18n from '../../../translations';

type Props = {
  contact: any;
  formControls: any;
  classes: any;
  index: number;
};
export const EmergencyContact: React.FC<Props> = ({
  contact,
  index,
  formControls,
  classes,
}) => {
  const { register, unregister, errors } = formControls;

  useEffect(() => {
    return () => {
      unregister(`emergencyContact.${index}`);
    };
  }, []);

  return (
    <>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('first_name')}
          type="text"
          formErrors={errors}
          defaultValue={contact.firstName}
          {...register(`emergencyContact.${index}.firstName`, {
            required: 'Please provide a first name',
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('last_name')}
          type="text"
          formErrors={errors}
          defaultValue={contact.lastName}
          {...register(`emergencyContact.${index}.lastName`, {
            required: 'Please provide a last name',
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('relationship')}
          type="text"
          formErrors={errors}
          defaultValue={contact.relationship}
          {...register(`emergencyContact.${index}.relationship`, {
            required: 'Please provide how this person is related to you',
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('phone_number')}
          type="text"
          formErrors={errors}
          defaultValue={contact.phoneNumber}
          {...register(`emergencyContact.${index}.phoneNumber`, {
            required: 'Please provide a contact number',
            ...sharedValidators.phoneNumber,
          })}
        />
      </div>
    </>
  );
};
