import { Colors } from '../../../config/colors';

export const styles = {
  contentBox: {
    margin: '9px 35px 0 60px',
  },
  purpleBox: {
    borderRadius: '10px',
    backgroundColor: Colors.linkPurple,
    color: Colors.white,
    margin: '10px 0',
    boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
    width: '100%',
  },
  titleAndX: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '18px 29px 20px 27px',
  },
  xBox: {
    height: '13px',
    width: '13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  editProfilePicBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: '25px',
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  profilePicture: {
    borderRadius: '100px',
    height: '85px',
    width: '85px',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    backgroundSize: 'cover',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '46rem',
  },
  modalProfilePic: {
    border: `3px solid ${Colors.linkPurple}`,
    borderRadius: '100px',
    marginBottom: '12px',
    width: '115px',
    height: '115px',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    backgroundSize: 'cover',
  },
  saveButton: {
    width: '21.2rem',
    height: '3.2rem',
    margin: '46px 0 32px',
  },
  firstDividerLine: {
    background: Colors.checkboxGray,
    width: '97%',
    height: '0.5px',
    margin: '18px 0 0 24px',
  },
};
