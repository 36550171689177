import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LinkComponent } from '..';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ArrowDown, ArrowUp, UserCircle } from '../../assets/images/icons';
import { MenuOption } from '../../config/SharedTypes';
import {
  deleteFhirItem,
  selectFamilyHistory,
  updateFhirItem,
} from '../../features/dashboard/dashboardSlice';
import { DashboardSections } from '../../features/dashboard/DashboardSections';
import { OneLineTextInput } from '../OneLineTextInput/OneLineTextInput';
import {
  CardDetail1,
  CardDetail2,
  CardHeader1,
  HorizontalBreak,
} from '../TextComponents';
import { ThemedButton } from '../ThemedButton/ThemedButton';
import { ThreeDotMenu } from '../ThreeDotMenu/ThreeDotMenu';
import { AddFamilyCondition } from './AddFamilyCondition';
import { ConditionDetail } from './ConditionDetail';
import { useCheckboxStyles, useFamilyDetailCardStyles } from './styles';
import {
  flattenFamilyHistory,
  formToFamilyCondition,
  mergeConditions,
} from './utils';
import { sharedValidators } from '../../config/constants';

interface FamilyDetailProps {
  edit?: boolean;
  add?: boolean;
  memberId: string;
  menuOptions?: MenuOption[];
  displayOnly?: boolean;
}

export const FamilyDetailCard: React.FC<FamilyDetailProps> = ({
  memberId,
  displayOnly = false,
}) => {
  const classes = useFamilyDetailCardStyles({
    displayOnly,
    theme: {},
  });
  const checkClasses = useCheckboxStyles();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList: familyHistories } = useAppSelector(selectFamilyHistory);
  const detail = familyHistories.find(hist => hist.fhirData.id === memberId);
  const flatDetail = flattenFamilyHistory(detail);
  const { id, name, bornDate, deceasedDate, condition, relationship } =
    flatDetail;
  const [isDetailsAdded] = useState(!!condition?.length);
  const [isCardOpen, setIsCardOpen] = useState(displayOnly);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deceased, setDeceased] = useState(!!deceasedDate);

  const conditionExisxtsAndIsEmpty = !!condition && !condition.length;
  const isAdditional = !!detail?.additional;

  const {
    register,
    unregister,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onDismiss = () => {
    const update = {
      detailItem: { fhirData: { ...detail.fhirData, condition: [] } },
      section: DashboardSections.FAMILY_HISTORY,
    };
    dispatch(updateFhirItem(update));
  };

  const onFormSubmit = data => {
    //Save Family History
    let update;
    if (isAdding && isDetailsAdded) {
      //adding a new condition
      update = {
        detailItem: {
          fhirData: {
            ...detail.fhirData,
            condition: mergeConditions(data, condition),
            relationship: { text: relationship },
          },
        },
        section: DashboardSections.FAMILY_HISTORY,
      };
    } else if (isEditing) {
      update = {
        detailItem: {
          fhirData: {
            ...detail.fhirData,
            name: data.member.name,
            bornDate: data.member.dob,
            deceasedDate: data.member.dod,
            relationship: { text: relationship },
          },
        },
        section: DashboardSections.FAMILY_HISTORY,
      };
    } else {
      //New Data
      const result = {
        ...(id && { id: id }),
        ...formToFamilyCondition(data, flatDetail),
      };
      update = {
        detailItem: result,
        section: DashboardSections.FAMILY_HISTORY,
      };
    }
    dispatch(updateFhirItem(update));
    unregister('conditions');
    setIsAdding(false);
    setIsEditing(false);
  };

  const menuOptions = () => {
    const optionsArr = [
      {
        title: t('edit'),
        onClick: () => {
          setIsEditing(true);
          setIsAdding(false);
        },
      },
    ];
    if (isAdditional) {
      optionsArr.push({
        title: t('delete'),
        onClick: () => {
          dispatch(
            deleteFhirItem({
              section: DashboardSections.FAMILY_HISTORY,
              id,
            })
          );
        },
      });
    }

    return optionsArr;
  };

  return (
    <>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <div className={classes.title}>
            <div className={classes.iconAndTitle}>
              <div className={classes.imageContainer}>
                <img width="29px" src={UserCircle} alt="userCircle" />
              </div>
              <CardHeader1>{relationship}</CardHeader1>
            </div>
            <div className={classes.controls}>
              {(conditionExisxtsAndIsEmpty || name) && !displayOnly && (
                <div className={classes.dotMenu}>
                  <ThreeDotMenu options={menuOptions()} />
                </div>
              )}
              {(isDetailsAdded || conditionExisxtsAndIsEmpty || name) &&
                !displayOnly && (
                  <div
                    className={classes.arrowContainer}
                    onClick={() => setIsCardOpen(!isCardOpen)}
                  >
                    <img
                      width="20px"
                      src={isCardOpen ? ArrowUp : ArrowDown}
                      alt="cardOpener"
                    />
                  </div>
                )}
            </div>
          </div>
          {isEditing && (
            <div className={classes.editContainer}>
              <OneLineTextInput
                placeholder={t('name')}
                type="text"
                defaultValue={name}
                {...register(`member.name`, {
                  required: `${t('their_name_required')}`,
                })}
                formErrors={errors}
              />
              <OneLineTextInput
                placeholder={t('dob_format')}
                type="text"
                defaultValue={bornDate}
                {...register(`member.dob`, {
                  ...sharedValidators.longDate,
                })}
                formErrors={errors}
              />
              <label
                className={checkClasses.container}
                onClick={e => {
                  setDeceased(!deceased);
                  e.preventDefault();
                }}
              >
                <CardDetail1>{t('deceased')}</CardDetail1>
                <input
                  type="checkbox"
                  checked={deceased}
                  {...register('member.deceased')}
                />
                <span className={checkClasses.checkmark} />
              </label>
              {deceased && (
                <>
                  <OneLineTextInput
                    placeholder={t('dod_format')}
                    type="text"
                    defaultValue={deceasedDate}
                    {...register(`member.dod`, {
                      ...sharedValidators.shortDate,
                    })}
                    formErrors={errors}
                  />
                </>
              )}
              <div className={classes.actionButtons}>
                <ThemedButton title={t('save')} type="submit" />
              </div>
            </div>
          )}
          <>
            {!!name && !isEditing && (
              <>
                <div className={classes.infoSection}>
                  <CardDetail1>{name}</CardDetail1>
                  {!!bornDate?.length && (
                    <CardDetail2>
                      {t('dob_title_case')} ● {bornDate}
                    </CardDetail2>
                  )}
                  {!!deceasedDate?.length && (
                    <CardDetail2>
                      {t('Deceased')} ● {deceasedDate}
                    </CardDetail2>
                  )}
                </div>
              </>
            )}
            {isCardOpen && (
              <>
                {condition?.length > 0 ? (
                  <div className={classes.conditionContainer}>
                    {condition.map(cond => {
                      return (
                        <>
                          <ConditionDetail
                            condition={cond}
                            famId={id}
                            isDeceased={deceased}
                            displayOnly={displayOnly}
                          />
                          <HorizontalBreak />
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <HorizontalBreak />
                    <div className={classes.noInfo}>
                      <CardDetail2>{t('no_info')}</CardDetail2>
                    </div>
                  </>
                )}

                {!isAdding && !displayOnly && (
                  <div className={classes.addAnother}>
                    <LinkComponent
                      label={
                        !!condition?.length
                          ? t('add_another_condition')
                          : t('add_conditions')
                      }
                      onClick={() => {
                        setIsAdding(true);
                        setIsEditing(false);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {!isDetailsAdded && !condition && !isAdding && !displayOnly && (
              <>
                <HorizontalBreak />
                <div className={classes.newCard}>
                  <LinkComponent
                    label={t('add_health_details')}
                    onClick={() => {
                      setIsAdding(true);
                    }}
                  />
                  {!condition && (
                    <LinkComponent
                      label={t('no_details')}
                      onClick={() => {
                        onDismiss();
                      }}
                    />
                  )}
                  <LinkComponent label={t('request_data')} isDisabled />
                </div>
              </>
            )}
            {isAdding && (
              <AddFamilyCondition
                familyMember={relationship}
                register={register}
                unregister={unregister}
                control={control}
                errors={errors}
                additional={isDetailsAdded || !!name}
                cancelAction={() => {
                  setIsAdding(false);
                }}
                isDeceased={deceased}
              />
            )}
          </>
        </form>
      </div>
    </>
  );
};
