import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  Dropdown,
  FilterTypeAhead,
  MarkCurrent,
  MarkSensitive,
  OneLineTextInput,
  ThemedButton,
} from '../../../components';
import {
  CardDetail1,
  CardDetail2,
  FormPrompt,
  HorizontalBreak,
} from '../../../components/TextComponents';
import { ROUTES, sharedValidators } from '../../../config/constants';
import { Medication } from '../../../config/SharedTypes';
import { selectSection, updateFhirItem } from '../dashboardSlice';
import { DashboardSections } from '../DashboardSections';
import { useMedicationsStyles } from './styles';
import { dosageUnits, flattenMedication, formToMedication } from './utils';
import { searchMedications } from '../../../api/info';
import { getUUID } from '../../../config/utilities';

type EditMedicationProps = {
  details?: Medication[];
};

export type EditMedicationForm = {
  title: { value: string; label: string } | string;
  startDate: string;
  endDate: string;
  dosageValue: number;
  dosageUnit: any;
  dosageForm: any;
  regimen: string;
  current: boolean;
  sensitive: boolean;
};

const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const EditMedication: React.FC<EditMedicationProps> = () => {
  const classes = useMedicationsStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { detailList } = useAppSelector(
    selectSection(DashboardSections.MEDICATIONS)
  );

  const handleSearchMedications = (term: string) => {
    let result = [
      { label: `${t('type_to_search')}`, value: `${t('type_to_search')}` },
    ];
    return new Promise(async resolve => {
      if (term.length > 3) {
        const results = await searchMedications(term);
        result =
          results?.data?.with?.map(item => ({ label: item, value: item })) ||
          [];
      }
      resolve(result);
    });
  };

  const currentMeds = detailList || [];
  const queryId = useQuery().get('id');
  const medId = queryId || getUUID();
  const history = useHistory();
  const medData = currentMeds.find(el => el.fhirData.id === medId);
  const flatMed = flattenMedication(medData);

  const defaultForm = medData
    ? { defaultValues: { title: flatMed.title } }
    : {};

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditMedicationForm>(defaultForm);

  const onSubmit = data => {
    const update = {
      section: DashboardSections.MEDICATIONS,
      detailItem: formToMedication(data, medId),
    };
    dispatch(updateFhirItem(update));
    history.push(ROUTES.medicationsDashboard);
  };
  const onError = errors => {
    console.error(errors);
  };

  const [isCurrent, setIsCurrent] = useState(flatMed?.current || false);

  return (
    <>
      <div className={classes.subheader}>
        <FormPrompt>{t('add_meds_subheader')}</FormPrompt>
      </div>
      <div className={classes.container}>
        <div className={classes.addMedContainer}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <CardDetail1>{t('meds_search_instructions')}</CardDetail1>
            <Controller
              name="title"
              control={control}
              rules={{ required: `${t('please_select_medication')}` }}
              render={({ field }) => (
                <FilterTypeAhead
                  field={field}
                  placeholder={`${t('search_for_medications_here')}`}
                  searchFunction={handleSearchMedications}
                  formErrors={errors}
                />
              )}
            />
            <HorizontalBreak />
            <MarkCurrent
              register={{ ...register('current') }}
              defaultValue={flatMed?.current}
              onChange={() => {
                setIsCurrent(!isCurrent);
              }}
            />
            <div className={classes.dates}>
              <div className={classes.dateContainer}>
                <OneLineTextInput
                  placeholder={t('approx_start_date')}
                  type="text"
                  defaultValue={flatMed?.startDate}
                  {...register('startDate', {
                    required:
                      'Please enter when you started taking this medication.',
                    ...sharedValidators.shortDate,
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
              </div>
              {!isCurrent && (
                <div className={classes.dateContainer}>
                  <OneLineTextInput
                    placeholder={t('approx_end_date')}
                    type="text"
                    defaultValue={flatMed?.endDate}
                    {...register('endDate', {
                      required:
                        'Please enter when you stopped taking this medication.',
                      ...sharedValidators.shortDate,
                    })} //TODO: Resolve type issue and use translations string
                    formErrors={errors}
                  />
                </div>
              )}
            </div>
            <HorizontalBreak />
            <div className={classes.dosage}>
              <div className={classes.dosageTextinput}>
                <OneLineTextInput
                  placeholder={t('dosage')}
                  type="text"
                  defaultValue={flatMed?.dosageValue}
                  {...register('dosageValue', {
                    required:
                      'Please enter the dosage amount of the medication.',
                    ...sharedValidators.integer,
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
              </div>
              <div className={classes.dosageTextinput}>
                <Controller
                  name="dosageUnit"
                  control={control}
                  defaultValue={flatMed?.dosageUnit}
                  shouldUnregister={true}
                  rules={{
                    required: 'Please select the dosage unit of the medication',
                  }} //TODO: Resolve type issue and use translations string
                  render={({ field }) => (
                    <Dropdown
                      field={field}
                      placeholder={t('dosage_unit')}
                      optionsArr={dosageUnits}
                      formErrors={errors}
                    />
                  )}
                />
              </div>
              <div className={classes.dropdownContainer}>
                <Controller
                  name="dosageForm"
                  control={control}
                  defaultValue={flatMed?.dosageForm}
                  shouldUnregister={true}
                  rules={{
                    required: 'Please select the dosage form of the medication',
                  }} //TODO: Resolve type issue and use translations string
                  render={({ field }) => (
                    <Dropdown
                      field={field}
                      placeholder={t('select_dose_form')}
                      optionsList={['Liquid', 'Pill', 'Intravenous']}
                      formErrors={errors}
                    />
                  )}
                />
              </div>
            </div>
            <OneLineTextInput
              placeholder={t('regimen')}
              type="text"
              defaultValue={flatMed?.regimen}
              {...register('regimen', {
                required: 'Please enter the regimen of the medication.',
              })} //TODO: Resolve type issue and use translations string
              formErrors={errors}
            />
            <HorizontalBreak />
            <MarkSensitive
              register={{ ...register('sensitive') }}
              defaultValue={medData?.sensitive}
            />
            <div className={classes.buttonContainer}>
              <ThemedButton title={t('save')} type="submit" />
            </div>
          </form>
        </div>
        <div className={classes.editAnytime}>
          <CardDetail2>{t('can_add_edit_all_caps')}</CardDetail2>
        </div>
      </div>
    </>
  );
};
