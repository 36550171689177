import { Colors } from '../../../config/colors';
import { DESKTOP_VIEW_BREAKPOINT } from '../../../config/constants';

export const styles = {
  contentBox: {
    marginTop: '88px',
    textAlign: 'left',
    width: '48rem',
  },
  title: {
    marginTop: '22px',
  },
  info: {
    margin: '10px 0 25px',
    color: Colors.secondaryText,
  },
  line: {
    height: '0.5px',
    width: '100%',
    margin: '25px 0 24px',
    background: Colors.checkboxGray,
  },
  linkContainer: {
    margin: '10px 0 42px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '339px',
    height: '51px',
    borderRadius: '10px',
    border: `2px solid ${Colors.logoutBorderGray}`,
    backgroundColor: Colors.white,
    color: Colors.logoutButtonText,
    cursor: 'pointer',
  },
  footer: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    justifyContent: 'space-evenly',
    height: '2.5rem',
    width: '48rem',
    [DESKTOP_VIEW_BREAKPOINT]: {
      justifyContent: 'flex-start',
      position: 'relative',
      marginTop: '30px',
      marginLeft: '40px',
      gap: '132px',
    },
  },
};
