/* eslint-disable react-hooks/rules-of-hooks */
import { useCheckboxStyles, useManagedCheckboxStyles } from '../styles';
import { useState } from 'react';

interface CheckboxProps {
  label?: string;
  onChange?: any;
  checkedState?: boolean;
  managed?: boolean;
  onClick?: any;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  onChange,
  managed,
  checkedState,
  onClick = () => {},
}) => {
  const classes = managed ? useManagedCheckboxStyles() : useCheckboxStyles();
  const [checked, setChecked] = useState(!!checkedState);
  return (
    <label
      className={classes.container}
      onClick={() => {
        setChecked(!checked);
        onClick();
        if (!managed) {
          onChange();
        }
      }}
    >
      {label && <div className={classes.label}>{label}</div>}
      <input type="checkbox" checked={managed ? checkedState : checked} />
      <span className={classes.checkmark} />
    </label>
  );
};
