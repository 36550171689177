import { useEffect } from 'react';
import { OneLineTextInput } from '../../../components';
import { sharedValidators } from '../../../config/constants';
import i18n from '../../../translations';

type AddressProps = {
  address: any;
  formControls: any;
  classes: any;
  index: number;
};
export const Address: React.FC<AddressProps> = ({
  address,
  index,
  formControls,
  classes,
}) => {
  const { register, unregister, errors } = formControls;

  useEffect(() => {
    return () => {
      unregister(`address.${index}`);
    };
  }, []);

  return (
    <>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('address')}
          type="text"
          formErrors={errors}
          defaultValue={address?.line[0]}
          {...register(`address.${index}.line`, {
            required: 'Please provide an address',
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={`${i18n.t('city')}`}
          type="text"
          defaultValue={address?.city}
          formErrors={errors}
          {...register(`address.${index}.city`, {
            required: 'Please provide a city',
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={`${i18n.t('state')}`}
          defaultValue={address?.state}
          type="text"
          formErrors={errors}
          {...register(`address.${index}.state`, {
            required: 'Please provide a state',
          })}
        />
      </div>
      <div className={classes.inputContainer}>
        <OneLineTextInput
          placeholder={i18n.t('zip_code')}
          type="text"
          defaultValue={address?.postalCode}
          formErrors={errors}
          {...register(`address.${index}.postalCode`, {
            required: 'Please provide a zip code',
            ...sharedValidators.zipCode,
          })}
        />
      </div>
    </>
  );
};
