import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import './styles.css';

type Props = {
  items?: any[];
  selectedIndex?: number;
  onSelect: (index: number) => void;
};

export const UserProfileCarousel: React.FC<Props> = ({
  items = [],
  selectedIndex = 0,
  onSelect,
}) => {
  const [currentSelected, setCurrentSelected] = useState(selectedIndex);

  const isShareUser = useSelector(
    (store: RootState) => !!store.session.shareUser
  );

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--carousel-position', `${currentSelected + 1}`);
    root.style.setProperty('--carousel-items', `${items.length}`);
    root.style.setProperty(
      '--carousel-middle',
      `${Math.round(items.length / 2)}`
    );
    items.forEach((item, index) => {
      const element = document.getElementById(`carouselItem${index}`);
      element.style.setProperty('--offset', `${index + 1}`);
      element.style.setProperty(
        'display',
        Math.abs(currentSelected - index + 1) > 3 ? 'none' : 'inherit'
      );
    });
  }, [items, currentSelected]);

  const handleSelection = index => {
    setCurrentSelected(index);
    onSelect(index);
  };
  return (
    <div className="body">
      {!isShareUser &&
        items.map((item, index) => (
          <input
            type="radio"
            name="position"
            value={item}
            checked={index === currentSelected}
            onClick={() => handleSelection(index)}
            style={{
              gridColumn: `${index + 2} / ${index + 3}`,
              gridRow: `2 / 3`,
            }}
          />
        ))}

      <main id="carousel">
        {items.map((item, index) => (
          <div
            className="item"
            key={`carouselItem${index}`}
            id={`carouselItem${index}`}
            onClick={() => handleSelection(index)}
          >
            {item}
          </div>
        ))}
      </main>
    </div>
  );
};
