import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { arrowLeft } from '../../../assets/images/icons';
import {
  ContentBox,
  Dropdown,
  OneLineTextInput,
  RadioList,
  ThemedButton,
} from '../../../components';
import {
  AboutMeTitle,
  FormHeader,
  FormSecondary,
} from '../../../components/TextComponents';
import {
  ROUTES,
  sharedValidators,
  YESNO_RADIO_OPTS,
} from '../../../config/constants';
import i18n from '../../../translations';
import { setDependentData } from '../../login/loginSlice';
import { useConfirmDependentStyles } from './styles';

export const ConfirmDependentInfo: React.FC = () => {
  const classes = useConfirmDependentStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue('responsibleFinancial', 'Y');
    setValue('responsibleMedical', 'Y');
  }, []);

  const onSubmit = data => {
    console.log(data); //ToDo: remove once we call API
    dispatch(setDependentData({ dependentInfo: data }));
    history.push(ROUTES.verifyDependent);
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleSection}>
        <div
          className={classes.backToAction}
          onClick={() => history.push(ROUTES.dashboardHome)}
        >
          <div className={classes.arrowContainer}>
            <img src={arrowLeft} alt="arrowLeft" />
          </div>
          <AboutMeTitle>{t('back_to_data_constellation')}</AboutMeTitle>
        </div>
        <div className={classes.buttonsBox}>
          <div
            onClick={() => {
              history.push(ROUTES.dashboardHome);
            }}
          >
            <AboutMeTitle>{t('cancel')}</AboutMeTitle>
          </div>
        </div>
      </div>
      <div className={classes.pageTitle}>
        <FormHeader>{t('add_info_about_person')}</FormHeader>
      </div>
      <div className={classes.contentBox}>
        <ContentBox title={''} dropshadow>
          <div className={classes.cardContent}>
            <form className={classes.form}>
              <div className={classes.inputPair}>
                <OneLineTextInput
                  placeholder={t('legal_first_name')}
                  type="text"
                  {...register('firstName', {
                    required: 'Please enter legal first name',
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
                <OneLineTextInput
                  placeholder={t('legal_last_name')}
                  type="text"
                  {...register('lastName', {
                    required: 'Please enter legal last name',
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
              </div>

              <div className={classes.inputPair}>
                <div className={classes.inputContainer}>
                  <OneLineTextInput
                    placeholder={t('dob')}
                    type="text"
                    {...register('dob', {
                      required:
                        'Please enter the date of birth in the format mm/dd/yyyy',
                      ...sharedValidators.longDate,
                    })} //TODO: Resolve type issue and use translations string
                    formErrors={errors}
                  />
                </div>
                <div className={classes.inputContainer}>
                  <Controller
                    name={'gender'}
                    control={control}
                    rules={{
                      required: 'Please select a gender',
                      //TODO: Resolve type issue and use translations string
                    }}
                    render={({ field }) => (
                      <Dropdown
                        field={field}
                        placeholder={t('select_gender')}
                        optionsList={[
                          i18n.t('gender_male'),
                          i18n.t('gender_female'),
                          i18n.t('other'),
                        ]}
                        formErrors={errors}
                      />
                    )}
                  />
                </div>
              </div>

              <div className={classes.inputPair} style={{ marginTop: '40px' }}>
                <OneLineTextInput
                  placeholder={t('address')}
                  type="text"
                  {...register('address', {
                    required: 'Please enter a street address',
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
                <OneLineTextInput
                  placeholder={t('city')}
                  type="text"
                  {...register('city', {
                    required: 'Please enter city name',
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
              </div>

              <div
                className={classes.inputPair}
                style={{ marginBottom: '40px' }}
              >
                <OneLineTextInput
                  placeholder={t('state')}
                  type="text"
                  {...register('state', {
                    required: 'Please enter a state',
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
                <OneLineTextInput
                  placeholder={t('zip_code')}
                  type="text"
                  {...register('zip', {
                    required: 'Please enter a 5 digit zip code',
                    ...sharedValidators.zipCode,
                  })} //TODO: Resolve type issue and use translations string
                  formErrors={errors}
                />
              </div>
              <div className={classes.relationshipSection}>
                <FormSecondary>{t('what_is_relationship')}</FormSecondary>
                <Controller
                  name={'relationship'}
                  control={control}
                  rules={{ required: 'Please select a relationship' }}
                  render={({ field }) => (
                    <Dropdown
                      field={field}
                      placeholder={t('select_relationship')}
                      optionsArr={[
                        { value: 'BC', label: i18n.t('bio_child') },
                        { value: 'DC', label: i18n.t('dependent_child') },
                        { value: 'DA', label: i18n.t('dependent_adult') },
                        { value: 'S', label: i18n.t('sibling') },
                        { value: 'SP', label: i18n.t('spouse') },
                        { value: 'BM', label: i18n.t('bio_mother') },
                        { value: 'BF', label: i18n.t('bio_father') },
                      ]}
                      formErrors={errors}
                    />
                  )}
                />
              </div>
              <div className={classes.radioQuestions}>
                <div>
                  <FormSecondary>{t('responsible_for_medical')}</FormSecondary>
                  <RadioList
                    optionsArr={YESNO_RADIO_OPTS}
                    register={{
                      name: `responsibleMedical`,
                      register: register,
                    }}
                  />
                </div>
                <div>
                  <FormSecondary>
                    {t('responsible_for_financial')}
                  </FormSecondary>
                  <RadioList
                    optionsArr={YESNO_RADIO_OPTS}
                    register={{
                      name: `responsibleFinancial`,
                      register: register,
                    }}
                  />
                </div>
              </div>
            </form>
            <div className={classes.continueButton}>
              <ThemedButton
                title={t('continue')}
                onClick={() => handleSubmit(onSubmit)()}
              />
            </div>
          </div>
        </ContentBox>
      </div>
    </div>
  );
};
